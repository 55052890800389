@import "atoms/variables";@import "functions";
$width-mapa: 800px;

@font-face {
  font-family: Deligante;
  src: url(./departamento/fonts/static/Deligante-Smithasa.ttf);
}
@font-face {
  font-family: QuickSandBold;
  src: url(./departamento/fonts/static/Quicksand-Bold.ttf);
}
@font-face {
  font-family: QuickSandMedium;
  src: url(./departamento/fonts/static/Quicksand-Medium.ttf);
}
@font-face {
  font-family: QuickSandRegular;
  src: url(./departamento/fonts/static/Quicksand-Regular.ttf);
}

.tempContainer {
  min-height: 50vh;
  background-color: $white;
  & > h2 {
    text-align: center;
    color: #233e7f;
    font-weight: bold;
    font-size: 36px;
    @media (max-width: $mobile-width) {
      font-size: 24px;
    }
  }
}

.container_homeWelcome {
  display: flex;
  flex-direction: row;
  gap: calc(rem(56px));
  padding: calc(rem(56px)) calc(rem(98px));
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: $width-mapa) {
    flex-direction: column;
    padding: 0;
  }
  & > p {
    width: calc(rem(647px));
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.0025em;
    color: $gray-scale-900;
    white-space: pre-wrap;
    @media (max-width: $width-mapa) {
      width: 100%;
      text-align: center;
      padding: 0 calc(rem(56px));
      text-align: start;
    }
  }
  & > h2 {
    font-family: Deligante;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 80px;
    letter-spacing: -0.0025em;
    color: $blue-primary-700;
    @media (max-width: $width-mapa) {
      text-align: center;
      padding: 0 calc(rem(36px));
    }
  }
}
.mapaContainer {
  display: flex;
  justify-content: space-around;
  background-color: $white;

  @media (max-width: $mobile-width) {
    display: block;
  }
  .mapaContent {
    margin-top: 7%;
    margin-left: -150px;
    @media (max-width: $mobile-width) {
      margin-top: 10%;
      margin-left: 5%;
      img {
        width: 98%;
        height: 98%;
      }
      margin-top: 0%;
      margin-left: 0;
    }
  }
  .textMapaContent {
    margin-left: 5%;
    @media (max-width: $mobile-width) {
      text-align: center;
      margin-left: 0%;
    }
    h1 {
      font-family: Quicksand;
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 0em;
      text-align: left;
      color: #233e7f;
      @media (max-width: $mobile-width) {
        text-align: center;
      }
    }
    .mapaSelectContent {
      margin-left: 30%;
      @media (max-width: $mobile-width) {
        margin-left: 0%;
      }
    }
  }
}
.landFill {
  fill: #a711d4;
  fill-opacity: 1;
  stroke: #a711d4;
  stroke-opacity: 1;
  stroke-width: 0.5;
}

.land {
  fill: #ededed;
  fill-opacity: 1;
  stroke: #ededed;
  stroke-opacity: 1;
  stroke-width: 0.5;
}

.containerSection {
  width: 100%;
  background-color: $white;

  @media (max-width: $mobile-width) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > h2 {
    text-align: center;
    color: #233e7f;
    font-weight: bold;
    font-size: 36px;
    @media (max-width: $mobile-width) {
      font-size: 24px;
    }
  }

  & > p {
    font-size: 28px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 54px;
    @media (max-width: $mobile-width) {
      font-size: 18px;
    }
  }

  .grid {
    padding: 0 rem(40px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 42px;
    row-gap: 38px;
    justify-items: center;

    @media (max-width: 800px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 rem(40px);
    }
  }

  .anchorMore {
    margin-top: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      font-size: rem(22.78px);
      font-weight: 500;
      color: #1b629d;
    }

    & > div {
      margin-left: rem(8px);
    }
  }

  @media screen and (max-width: 700px) {
    padding: rem(68px) rem(16px);

    & > h2 {
      text-align: center;
    }

    & > p {
      text-align: left;
    }
  }

  .mobileButtons {
    margin: rem(31px);
    display: block;

    @media (min-width: $mobile-width) {
      display: none;
    }
  }
}



.containerSlider {
  width: 100%;
  height: 700px;
  margin: 0 auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.2);

  @media screen and (max-width: 700px) {
    height: 450px;
  }
}

.containerCard {
  width: 100%;
  height: rem(425px);
  overflow: hidden;
  margin: 0 auto 0;
  display: flex;
  justify-content: center;

  @media (min-width: $mobile-width) {
    height: 100%;
    position: static;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 60px;
    justify-content: center;
  }

  & > div:last-child {
    margin-right: 0;
  }
}

.containerPaginationCard {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;

  @media (min-width: $mobile-width) {
    opacity: 1;
    position: static;
  }
}

.deparmentCard {
  width: 80%;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #d3d4dd;
  background: #ffffff;

  @media (min-width: $mobile-width) {
    width: 287px;
    min-width: 277px;
    min-height: 380px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border-radius: 10px;
    border: 1px solid #d3d4dd;
    background: #ffffff;
  }
  & > div {
    height: 230px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .infoContainer {
    position: relative;
    margin: 16px 24px;
    height: 190px;

    & > h3 {
      width: 260px;
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 20.25px;
      margin-bottom: 20px;
      align-items: center;
    }

    & > p {
      margin-bottom: 56px;
      font-size: 15px;
    }

    a {
      font-size: 16px;
      font-weight: 500;
      color: #1b629d;
    }
    .anchorInfo {
      margin-top: 70px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      & > div {
        display: flex;
        align-items: center;
        padding-top: calc(rem(10px));
        gap: calc(rem(10px));
      }
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}

.btnSlide {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.containerDots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background-color: #233e7f;

  &.active {
    background: #f1f1f1;
  }
}

.thumbnail {
  background-repeat: no-repeat;
  background-size: cover;
  height: rem(400px);
  width: rem(583px);
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(rem(298px));
  }

  & > div {
    width: rem(292px);
    height: rem(384px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 1px solid #8f8fa2;
    border-radius: 10px;
    position: relative;

    & > img {
      margin-bottom: 16px;
    }

    @media (max-width: 800px) {
      width: calc(rem(265px));
    }

    .infoContainer {
      & > h3 {
        width: 260px;

        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 20.25px;
        margin-bottom: 8px;
        align-items: center;
      }

      & > p {
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.0025em;
      }

      a {
        font-size: 16px;
        font-weight: 500;
        color: #1b629d;
        position: absolute;
        bottom: 16px;
        right: 18px;
      }
      .anchorInfo {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}

.offerCard {
  width: 100%;
  min-width: 277px;
  max-width: 277px;
  height: 466px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  border-radius: 10px;
  border: 1px solid #d3d4dd;
  background: #ffffff;

  @media (max-width: $mobile-width) {
    min-height: 380px;
    height: rem(426px);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: 1px solid #aaaab9;
    border-radius: 10px;
  }

  img {
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  p {
    font-weight: 400px;
    font-size: 15px;
  }

  h4 {
    font-size: rem(20.25px);
    font-weight: bold;
    margin: 16px 0 8px 0;
  }

  h3 {
    font-size: rem(20.25px);
    font-weight: bold;
    margin: 16px 0 8px 0;
  }

  .calificationContainer {
    margin-top: 8px;
    margin-bottom: 16px;

    & > span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .containerContent {
    padding: 8px 16px 23px 16px;
  }

  .footerContent {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 16px;
  }

  .imgLabel {
    width: 60px;
    height: 33px;
    background: linear-gradient(90deg, #2a641f 0%, #5cc943 100%);
    padding: 4px 8px;
    border-radius: 5px 0px 5px 0px;
    position: absolute;

    & > span {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #ffffff;
    }
  }

  .offerPrice {
    font-size: rem(23.63px);
    font-weight: 500;
    color: #066030;
  }
}

.textLgGray {
  color: #515162;
}

.textDrkGray {
  color: #24252e;
}

.notRealPrice {
  text-decoration: line-through;
}

.bgOfferClose {
  background-color: #f7f7f7;
}

.activeAnim {
  opacity: 1;
}

.w100 {
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  min-width: 276px;
  max-width: 276px;
  @media (max-width: $mobile-width) {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    min-width: 576px;
    max-width: 576px;
  }
}



.content_offers_tourist {
  display: flex;
  width: 100%;
  // flex-direction: column;
  // padding: calc(rem(64px)) calc(rem(70px)); 
  gap: 10px;
  flex-flow: nowrap !important;
  justify-content: center;

  @media (max-width: 1250px) {
    padding: calc(rem(30px)) 0;
  }

  & > p {
    padding: calc(rem(24px)) 0;
    text-align: center;
    color: #233e7f;
    font-weight: bold;
    font-size: 36px;
    @media (max-width: $mobile-width) {
      font-size: 24px;
    }
  }

  & > .content_cards_offers {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media (max-width: 1276px) {
      // justify-content: space-between;
      padding: 0 calc(rem(15px));
      align-items: unset;
      overflow: hidden;
      width: 100%;
      height: auto;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
    }
    

    @media (max-width: 500px) {
      // overflow: scroll;
      overflow: hidden;
    }

    @media (max-width: $mobile-width) {
      padding: 0 calc(rem(20px));
    }

    & > .content_cards_offers_item {
      position: relative; /* Importante para posicionar elementos hijos con posición absoluta */
      width: 277px;
      height: 450px;
      background-color: $white;
      border: 1px solid #aaaab9;
      border-radius: 10px;
      cursor: pointer;

      @media (max-width: 1276px) {
        min-width: 277px;
      }

      & > img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: rem(150px);
        width: 100%;
        object-fit: cover;
      }

      & > .department_text {
        padding: calc(rem(10px)) calc(rem(24px)) 0 calc(rem(24px));
        font-family: QuickSandRegular;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.0025em;
        color: $gray-scale-700;
      }

      & > .title_text {
        display: -webkit-box; /* Permite el uso de line-clamp */
        -webkit-box-orient: vertical; /* Configura la orientación del contenedor */
        -webkit-line-clamp: 2; /* Limita el texto a dos líneas */
        line-clamp: 2;
        overflow: hidden; /* Esconde el contenido excedente */
        text-overflow: ellipsis;
        padding: calc(rem(7px)) calc(rem(24px)) 0 calc(rem(24px));
        font-weight: 700;
        font-size: 20.25px;
        line-height: 25px;
        letter-spacing: -0.0025em;
        color: $gray-scale-900;
      }

      & > .subtitle_text {
        padding: calc(rem(10px)) calc(rem(24px)) 0 calc(rem(24px));
        font-family: QuickSandRegular;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.0025em;
        color: $gray-scale-600;

        & > b {
          color: $blue-primary-300;
        }
      }

      & > .group_star {
        display: flex;
        flex-direction: row;
        padding: calc(rem(5px)) calc(rem(24px)) 0 calc(rem(24px));
        gap: calc(rem(4px));
      }

      & > .card_paragraph {
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow:auto;
        text-overflow: ellipsis;
        padding: calc(rem(10px)) calc(rem(24px)) calc(rem(24px)) calc(rem(24px));
        font-family: QuickSandRegular;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.0025em;
        color: $gray-scale-900;
        width: rem(245px);
        max-height: rem(110px);
        min-height: rem(110px);
      }

      & > .group_price {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        width: 100%; /* Asegura que ocupe el ancho completo de la tarjeta */
        background-color: white; /* Fondo blanco */
        padding: 9px calc(rem(14px));
        bottom: 0;
        z-index: 10; /* Eleva el contenedor por encima de otros elementos */

        & > .desde {
          color: $gray-scale-900;
          font-size: 16px;
          line-height: 20px;
        }

        & > .price_sub {
          color: $gray-scale-900;
          font-size: 14px;
          line-height: 18px;
        }

        & > .price_green {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: $green-800;
        }
      }
    }
  }

  & > .content_group_buttons {
    padding-top: calc(rem(24px));
    padding-bottom: calc(rem(20px));
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;

    & > button {
      padding: 8px 14px;
      background: $gray-scale-100;
      color: $blue-primary-700;
      border: 1px solid #233e7f;
      border-radius: 5px;
      font-size: calc(rem(24px));
      font-weight: 900;
    }

    & > button:disabled {
      background: $gray-scale-100;
      color: $gray-scale-400;
      border: 1px solid $gray-scale-400;
      cursor: not-allowed;
    }

    & > p {
      font-size: calc(rem(24px));
      font-weight: 900;
      color: $blue-primary-700;
    }
  }
}


.control_buttons_slider {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 1100px) {
    display: flex;
  }

  & > .button_slider {
    padding: 8px;
    width: 36px;
    height: 36px;
    background: transparent;
    border: 1px solid #233e7f;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  & > p {
    font-weight: 500;
    font-size: calc(rem(18px));
    line-height: calc(rem(22px));
    letter-spacing: -0.0025em;
    color: $blue-primary-500;
    padding-right: calc(rem(20px));
  }
  &:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
}
.rotate {
  margin-right: 2rem;

  & > img {
    transform: rotate(180deg);
  }
}

.containerCardsDeparments {
  padding: 0rem 4.375rem;

  @media (max-width: 1276px) {
    padding: 0 0.9375rem;
  }
  & > .h2_up_cards {
    //font-family: QuickSandBold;
    font-style: normal;
    font-weight: 700;
    font-size: 36.49px;
    line-height: 46px;
    letter-spacing: -0.0025em;
    color: #24252e;
    @media (max-width: $mobile-width) {
      font-size: 28px;
      line-height: 36px;
      margin: 0;
    }
  }

  & > .cards_meet_other_towns {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;
    height: 380px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    justify-content: center;

    @media (max-width: 1275px) {
      justify-content: space-between;
    }
    @media (max-width: 500px) {
      overflow-x: scroll;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & > .card_meet_other_towns {
      flex: 0 0 auto;
      cursor: pointer;
      width: 277px;
      background: #ffffff;
      border: 1px solid #d3d4dd;
      border-radius: 10px;
      margin: 0 1rem;

      & > .content_icon_other_towns {
        position: relative;
        width: 100%;
        height: 190px;
        overflow: hidden;

        & > .img_top_other_towns {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      & > .content_card_other_towns {
        position: relative;
        padding: 0 calc(rem(24px)) 0 calc(rem(24px));

        & > div {
          //font-family: QuickSandBold;
          font-weight: 700;
          font-size: calc(rem(24px));
          padding-top: calc(rem(16px));
          line-height: calc(rem(30px));
          color: #000000;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > p {
            font-weight: 400;
            font-size: 15px;
            height: calc(rem(76px));
            line-height: 18.75px;
            letter-spacing: -0.0025em;
            color: #24252e;
          }

          & > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            & > div {
              display: flex;
              align-items: center;
              padding-top: calc(rem(10px));
              gap: calc(rem(10px));
              cursor: pointer;
              & > p {
                font-weight: 500;
                color: #1b629d;
                font-size: calc(rem(14px));
                line-height: calc(rem(17.5px));
                letter-spacing: -0.0025em;
              }
              & > img {
                width: calc(rem(14px));
                height: calc(rem(14px));
              }
            }
          }

          & > img {
            text-decoration: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
