@import "atoms/variables";@import "functions";
@import './atoms/variables';

.content__title_region {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.0025em;
  font-weight: 700;
  font-size: 70px;
  font-family: $main-font;
  padding-top: calc(rem(80px));
  @media (max-width: $mobile-width) {
    text-align: center;
    bottom: 8rem;
    padding: 80px 15px 0 15px;
  }

  & > p {
    color: $blue-primary-700;
  }
}
.content__subtitle_region {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: $blue-primary-700;
  font-family: $main-font;
  line-height: 32px;
  font-size: 25.63px;
  padding-bottom: calc(rem(60px));
  @media (max-width: $mobile-width) {
    padding: 0 50px 60px 50px;
    text-align: center;
  }
}

.content__image_region {
  position: relative;
  width: 100%;
  object-fit: cover;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1100px) {
    height: auto;
  }

  & > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.content_region {
  //padding: 1rem 2rem;
  position: relative;
  overflow: hidden;

  & > .content__paragraph {
    padding: 2rem 8rem;
    @media (max-width: $mobile-width) {
      padding: 2rem;
    }
    & > div {
      width: 100%;
      & > p {
        line-height: 22px;
        letter-spacing: -0.0025em;
        font-size: 18px;
        font-family: $main-font;
        color: #24252e;
      }
    }
    & > h1 {
      width: 461px;
      height: 41px;
      left: 185px;
      top: 1220px;

      /* H2/max/700 */

      font-family: $main-font;
      font-style: normal;
      font-weight: 700;
      font-size: 32.44px;
      line-height: 41px;
      /* identical to box height */

      letter-spacing: -0.0025em;

      /* Primary/main blue */

      color: #233e7f;

      @media (max-width: $mobile-width) {
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
      }
    }
  }
}

.maps {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: $mobile-width) {
    flex-direction: column-reverse;
  }

  & > .changing_image {
    max-width: 26rem;
    height: 100%;
    overflow: hidden;

    @media (max-width: $mobile-width) {
      width: 90%;
    }

    & > img {
      width: 800px;
      height: 550px;
      margin-left: -12rem;
    }
  }

  & > .map {
    @media (max-width: $mobile-width) {
      width: 85%;
    }

    & > .image {
      height: 35rem;
      max-width: 26rem;
    }

    @media (max-width: 960px) {
      & {
        flex-direction: column-reverse;
      }
      & > div:nth-child(2) {
        top: 13rem;
      }
      & > div:nth-child(3) {
        top: 18rem;
      }
      & > div:nth-child(4) {
        top: 20rem;
      }
      & > div:nth-child(5) {
        top: 22rem;
      }
    }
    @media (min-width: 959px) {
      & {
        justify-content: space-between;
      }
      & > div:nth-child(2) {
        top: 14rem;
      }
      & > div:nth-child(3) {
        top: 18rem;
      }
      & > div:nth-child(4) {
        top: 20rem;
      }
      & > div:nth-child(5) {
        top: 22rem;
      }
    }

    & > div:nth-child(3) {
      padding-left: 5rem;
    }
    & > div:nth-child(4) {
      padding-left: 4rem;
    }
    & > div:nth-child(5) {
      margin-left: -1rem;
    }

    & > .button_text {
      position: absolute;
      display: flex;
      align-items: center;
      /* top: 2rem; */
      font-family: $main-font;
      cursor: pointer;

      &__selected {
        position: absolute;
        display: flex;
        align-items: center;
        cursor: pointer;

        & > a {
          background-color: #ffa400;
          color: black;
          text-decoration: none;
          border-radius: 2rem;
          padding: 0.2rem;
        }

        & > div {
          background-color: #fde7c3;
          height: 1rem;
          width: 1rem;
          border-radius: 2rem;
          & > div {
            background-color: #ffa400;
            height: 0.5rem;
            width: 0.5rem;
            margin: 4px auto;
            border-radius: 2rem;
          }
        }
      }

      & > a {
        text-decoration: none;
        color: black;
        background-color: white;
        border-radius: 2rem;
        padding: 0.2rem;
        margin-right: 0.5rem;
      }

      & > div {
        background: #bfcded;
        height: 1rem;
        width: 1rem;
        border-radius: 2rem;

        & > div {
          background-color: #2c4fa0;
          height: 0.5rem;
          width: 0.5rem;
          margin: 4px auto;
          border-radius: 2rem;
        }
      }
    }
  }
}

.content__info {
  padding: 1rem 4rem 4rem 4rem;
  font-family: $main-font;
  background-color: #dfe6f6;

  @media (max-width: $mobile-width) {
    padding: 1rem;
  }

  & > h1 {
    width: 400px;
    height: 41px;
    left: 194px;
    top: 2015px;

    /* H2/max/700 */

    font-family: $main-font;
    font-style: normal;
    font-weight: 700;
    font-size: 32.44px;
    line-height: 41px;
    /* identical to box height */

    letter-spacing: -0.0025em;

    /* Primary/main blue */

    color: #233e7f;
  }
  & > p {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media (max-width: $mobile-width) {
      grid-template-columns: 1fr;
    }
    & > div > div {
      display: flex;
      // justify-content: space-between;

      & > div {
        margin-left: 3%;
      }
    }
  }
}

.control_buttons_slider {
  display: flex;
  @media (max-width: $mobile-width) {
    justify-content: space-between;
    padding: 1rem 0;
  }

  & > .button_slider {
    padding: 8px;
    width: 36px;
    height: 36px;
    background: #f0f0f5;
    border: 1px solid #233e7f;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
}

.rotate {
  margin-right: 2rem;

  & > img {
    transform: rotate(180deg);
  }
}

.cop16_experience_section {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;
  max-width: 100%;
  background-image: url('/images/cop16/background-cop16-pacifico-colombia.png');
  background-repeat: space;
  background-size: auto;
  height: 100dvh;
  background-position: bottom left;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-width) {
    padding: 1rem 1rem;
  } 

  @media (max-width: 1200px) {
    min-height: 100dvh;
    height: 100%;
    
  }
  @media (max-width: 500px) {
    min-height: 100dvh !important;
    height: 100%;
    
  }
  @media (max-width: $mobile-width) {
    min-height: 100dvh !important;
    height: 100%;
    
  }

  & > div:first-child {
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
    padding: 3.5rem 6.125rem;
    align-items: center;
    justify-content: center;
    width: 100%;    

    @media (max-width: 1200px) {
      flex-direction: column;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      padding: 0.5rem 0.125rem;
      text-align: center;
    }
    @media (max-width: $mobile-width) {
      flex-direction: column;
    }
    & > div:first-child > h1{
      color: $cop16-primary-green
    }
    & > div:last-child > video {
      width: auto;
      min-width: 600px;
      max-width: 100%;
      @media (max-width: 500px) {
        width: auto;
        min-width: 350px;
        max-width: 100%;
      }
    }
  }
}

.cop16_experience_section_offers {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;
  max-width: 100%;
  background-image: url('/images/cop16/background-cop16-blanco-pacifico-colombia.png');
  background-color: $cop16-primary-green;
  background-repeat: space;

  background-position: bottom right;
  min-height: 100vh;
  @media (max-width: $mobile-width) {
    padding: 1rem 1rem;
  }
  & > p:first-child{
    text-align: center;
    letter-spacing: -0.0025em;
    font-weight: 700;
    font-size: 50px;
    font-family: $main-font;      
  }
  & > .cop16_experience_content__subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-family: $main-font;
    line-height: 32px;
    font-size: 20px;
    padding-bottom: calc(rem(25px));
  @media (max-width: $mobile-width) {
    padding: 0 50px 60px 50px;
    text-align: center;
  }
  }
  & > p {
    color: white;
  }
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    @media (max-width: $mobile-width) {
      display: block;
    }
  }
  & > div:last-child {
    & > p {
      font-weight: normal;      
      font-family: $main-font;
      line-height: 32px;
      font-size: 20.63px;
      color: white;
      text-align: center;
      padding: 16px
    }
  }
}

.cop16_experience_section_map{
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;
  max-width: 100%;
  background-image: url('/images/cop16/background-pacifico-colombia-cop16.png');
  background-color: white;
  background-repeat: space;
  background-position: bottom left;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (max-width: $mobile-width) {    
    padding: 0.5rem 1.125rem;      
  }
  
  & > div:first-child {
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
    padding: 3.5rem 6.125rem;
    align-items: center;
    justify-content: center;
    width: 100%;   
    @media (max-width: $mobile-width) {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.125rem;
      text-align: center;
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 66%;
      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0.125rem;
        text-align: center;        
      }

      & > p:first-child {
        font-size: 50px;
        color: $cop16-primary-green;
        font-weight: bold;

        @media (max-width: $mobile-width) {
          font-size: 30px;             
        }    
      }

      & > p:nth-child(2) {        
        font-size: 50px;
        color: $cop16-primary-green;
        font-weight: bold;
        @media (max-width: $mobile-width) {
          font-size: 35px;             
        }    
      }
      & > p:nth-child(3) {        
        font-size: 15px;        
      }
      
      & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 10%;     

        @media (max-width: $mobile-width) {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0rem;
          text-align: center;

        } 
        & > aside {
             
          & > h2 {
            font-weight: bold;
            @media (max-width: $mobile-width) {
              text-align: center;              
            } 
          }
          & ul {  
            padding: 0;
            margin: 0;
            & > li {          
              font-weight: bold;
              @media (max-width: $mobile-width) {
                list-style: none;
             
            } 
            }
          }
        }
        
        
      }
      
    }
  }
  
}


.cop16_experience_section_biodiversity {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;
  max-width: 100%;
  background-image: url('/images/cop16/background-cop16-blanco-pacifico-colombia.png');
  background-color: $cop16-primary-green;
  background-repeat: space;
  background-position: bottom right;
  min-height: 100vh;

  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  @media (max-width: $mobile-width) {
    padding: 1rem 1rem;
  }
  
  & > div > div:first-child {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;    
    @media (max-width: $mobile-width) {
      display: flex;
      flex-direction: column;
      width: 100%;      
    }

    & > .cop16_experience_section_biodiversity_paragraph {
      max-width: 960px;
      margin: 50px 0;
    }

    & > p:first-child {
      font-size: 50px;
      color: white;      
      font-weight: bold;

      @media (max-width: $mobile-width) {
        font-size: 30px;             
      }    
    } 
    & > p:nth-child(2) {        
      font-size: 20px;
      color: white;
      font-weight: bold;
      @media (max-width: $mobile-width) {
        font-size: 35px;             
      }    
    }
     
    
  }

  & > div > div:last-child {    
    width: 800px;
    margin: 0 auto;
    @media (max-width: $mobile-width) {
      width: 400px;
      margin: 0 auto;      
    }
  }
}
// meet_other_towns
.meet_other_towns {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;
  max-width: 100%;
  background-image: url('/images/logo-fondo.png');
  background-repeat: space;
  background-position: center;
  @media (max-width: $mobile-width) {
    padding: 1rem 1rem;
  }

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    @media (max-width: $mobile-width) {
      display: block;
    }
  }
}
.h2_up_cards {
  //font-family: QuickSandBold;
  font-style: normal;
  font-weight: 700;
  font-size: 36.49px;
  line-height: 46px;
  letter-spacing: -0.0025em;
  color: #24252e;
  @media (max-width: $mobile-width) {
    font-size: 28px;
    line-height: 36px;
  }
}

.cards_meet_other_towns {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
  height: 380px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  justify-content: space-between;

  @media (max-width: 1200px) {
    overflow-x: hidden;
  }
  @media (max-width: 500px) {
    overflow-x: scroll;
  }

  & > .card_meet_other_towns {
    flex: 0 0 auto;
    width: 277px;
    background: #ffffff;
    border: 1px solid #d3d4dd;
    border-radius: 10px;
    margin: 0 1rem;

    & > .content_icon_other_towns {
      position: relative;
      width: 100%;
      height: 190px;
      overflow: hidden;

      & > .img_top_other_towns {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    & > .content_card_other_towns {
      position: relative;
      padding: 0 calc(rem(24px)) 0 calc(rem(24px));

      & > div {
        //font-family: QuickSandBold;
        font-weight: 700;
        font-size: calc(rem(24px));
        padding-top: calc(rem(16px));
        line-height: calc(rem(30px));
        color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > p {
          font-weight: 400;
          font-size: 15px;
          height: calc(rem(76px));
          line-height: 18.75px;
          letter-spacing: -0.0025em;
          color: #24252e;
        }

        & > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          & > div {
            display: flex;
            align-items: center;
            padding-top: calc(rem(10px));
            gap: calc(rem(10px));
            cursor: pointer;
            & > p {
              font-weight: 500;
              color: #1b629d;
              font-size: calc(rem(14px));
              line-height: calc(rem(17.5px));
              letter-spacing: -0.0025em;
            }
            & > img {
              width: calc(rem(14px));
              height: calc(rem(14px));
            }
          }
        }

        & > img {
          text-decoration: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.video {
  width: 100%;
  height: fit-content;
}
